import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ImageUpload } from '../models/general.models'
import { MatDialog } from '@angular/material/dialog'
import { SliderUploaderComponent } from 'src/app/modules/products/components/slider-uploader/product-slider-uploader.component'
import { Subscription } from 'rxjs'
import { CustomizingMobileAppService } from 'src/app/modules/customizing-mobile-app/services/customizing-mobile-app.service'
import { environment } from 'src/environments/environment'
import { getStoreImagesInterface } from 'src/app/modules/customizing-mobile-app/models/customizing-market-place.models'

@Component({
	selector: 'app-square-img-slider',
	templateUrl: './square-img-slider.component.html',
	styleUrl: './square-img-slider.component.scss',
})
export class SquareImgSliderComponent {
	Subscription = new Subscription()
	@Output() newImageData: EventEmitter<{
		id: number | null
		orderInSlider: number
		imageUrl: string
	}> = new EventEmitter()
	@Output() selectedImageOutput: EventEmitter<getStoreImagesInterface | null> =
		new EventEmitter()
	@Output() deleteImgEmitter: EventEmitter<getStoreImagesInterface> =
		new EventEmitter()
	@Output() replacementImage: EventEmitter<getStoreImagesInterface> =
		new EventEmitter()
	@Output() allImagesEmitter: EventEmitter<getStoreImagesInterface[]> =
		new EventEmitter()
	@Output() OrderEmitter: EventEmitter<
		[
			{
				order: number
				sliderItemId: number
				sliderNo: number
			}
		]
	> = new EventEmitter()
	@Input() images: any = []
	selectedImage!: getStoreImagesInterface | null
	attachmentLink: string = environment.attachmentPath
	mobileConfig: ImageUpload = {
		imageCropDimensions: 1 / 1,
		imageWidth: 500,
		imageHeight: 500,
	}
	activeImage: number | null = null
	unfinishedImage: any[] = []
	arrayOrder: any = []
	constructor(
		private dialog: MatDialog,
		private CustomizingMobileAppService: CustomizingMobileAppService
	) {}
	ngOnChanges() {
		this.checkUnfinishedImages()
		let filteredArray = this.images?.length
			? this.images?.filter(
					(ele: any) => ele?.imageUrl == this.selectedImage?.imageUrl
			  )
			: null
		this.selectedImageOutput.emit(
			filteredArray?.length > 0 ? filteredArray[0] : this.selectedImage
		)
	}
	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.images, event.previousIndex, event.currentIndex)
		this.updateOrder()
	}

	uploadSliderImage(action: string, index?: number): void {
		const dialogRef = this.dialog.open(SliderUploaderComponent, {
			width: '752px',
			height: '450px',

			data: { mobile: this.mobileConfig },
		})
		dialogRef.afterClosed().subscribe((result: any) => {
			if (result) {
				this.uploadImage(result.selectedFile, action, index)
			}
		})
	}
	uploadImage(file: { croppedMobile: File }, action: string, index?: number) {
		const formData = new FormData()
		formData.append('image', file.croppedMobile)
		this.Subscription.add(
			this.CustomizingMobileAppService.uploadHomeSliderImage(
				formData
			).subscribe((response: any) => {
				let img = index != undefined ? this.images[index] : null

				if (action == 'add') {
					let img = {
						id: null,
						orderInSlider: this.images?.length + 1,
						imageUrl: response.result.url,
					}
					this.images.push(img)
					this.checkUnfinishedImages()
					this.newImageData.emit(img)
				} else {
					index ? (this.images[index].imageUrl = response.result.url) : null
					this.selectedImage
						? (this.selectedImage.imageUrl = response.result.url)
						: null
					this.selectedImage
						? this.replacementImage.emit(this.selectedImage)
						: null
				}
			})
		)
	}
	checkUnfinishedImages() {
		this.images?.length
			? (this.unfinishedImage = this.images?.filter(
					(ele: any) => ele?.id == null
			  ))
			: null
	}
	updateOrder() {
		this.images.map((ele: any, index: any) => (ele.orderInSlider = index + 1))
		this.getSliderOrder()
	}
	deleteImg(orderInSlider: any) {
		this.images = this.images.filter(
			(ele: any) => ele.orderInSlider !== orderInSlider
		)
		this.checkUnfinishedImages()
		if (this.images.length >= 1) {
			this.selectedImage = this.images[orderInSlider - 2]

			this.selectedImage
				? this.selectedImageOutput.emit(this.selectedImage)
				: null
			this.activeImage = orderInSlider - 1
		} else if (this.images.length == 0) {
			this.selectedImage = null
			this.selectedImageOutput.emit(this.selectedImage)
		}
	}
	replaceImage(index: any) {
		this.uploadSliderImage('edit', index)
	}
	getSliderOrder() {
		this.arrayOrder = []
		this.images.forEach((ele: any) => {
			if (ele?.id) {
				let image = {
					sliderNo: ele?.sliderNumber,
					sliderItemId: ele?.id,
					order: ele.orderInSlider,
				}
				this.arrayOrder.push(image)
			}
		})
		this.OrderEmitter.emit(this.arrayOrder)
	}
	emitAllImages() {
		this.allImagesEmitter.emit(this.images)
	}
}
