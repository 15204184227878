<main class="relative">
	<div
		cdkDropList
		cdkDropListOrientation="horizontal"
		class="flex flex-row {{ images?.length! > 0 ? 'overflow-x-scroll' : '' }} {{
			images?.length < 10 && unfinishedImage?.length! == 0
				? ' w-[75%]'
				: 'w-full'
		}} h-[210px]"
		(cdkDropListDropped)="drop($event)"
	>
		<div
			*ngFor="let image of images; index as i"
			cdkDrag
			(click)="
				selectedImageOutput.emit(image);
				selectedImage = image;
				activeImage = i + 1
			"
		>
			<div
				class="p-[8px] {{
					activeImage == i + 1 ? 'border-[1px] border-salon' : ''
				}} rounded-[7.5px] w-[200px] h-[200px]"
			>
				<div class="relative">
					<img [src]="attachmentLink + image?.imageUrl" alt="" />
					<div
						(click)="deleteImgEmitter.emit(image); deleteImg(i + 1)"
						class="w-[32px] h-[32px] bg-off-white rounded-full shadow absolute top-[12px] left-[12px] p-[10px] cursor-pointer"
					>
						<img src="./assets/images/Xicon.svg" alt="" />
					</div>
					<div
						class="{{
							activeImage == i + 1 ? 'bg-salon' : 'bg-medium-gray'
						}} rounded-tr-[7.5px] rounded-bl-[7.5px] text-off-white absolute top-0 right-0 w-[32px] h-[26px] text-center"
					>
						{{ i + 1 }}
					</div>
					<div
						(click)="replaceImage(i)"
						class="w-[36px] h-[36px] bg-off-white rounded-full shadow absolute bottom-[10px] right-[10px] p-[8px] cursor-pointer"
					>
						<img src="./assets/images/camera.svg" alt="" />
					</div>
				</div>
			</div>
		</div>
	</div>
	<div
		*ngIf="images?.length < 10 && unfinishedImage?.length! == 0"
		(click)="uploadSliderImage('add')"
		class="{{
			images?.length! > 0
				? 'absolute ltr:right-[-32px] rtl:left-0 top-0 px-2 '
				: 'w-full absolute ltr:right-0 rtl:left-0 top-0'
		}} bg-off-white cursor-pointer"
	>
		<button
			class="border-dashed border-[2px] border-[#D9DBE9]  h-[200px]  rounded-12px {{
				images?.length! > 0 ? 'w-[200px]' : 'w-full'
			}}"
		>
			<div>
				<img class="m-auto" src="./assets/images/upload.svg" alt="" />
				<p>{{ 'ACTIONS.UPLOAD_IMG' | translate }}</p>
			</div>
		</button>
	</div>
</main>
